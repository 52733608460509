@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~antd/dist/reset.css';

@layer base {
  html {
    font-family: Montserrat, system-ui, sans-serif !important;
  }
}

@layer components {
  .bg-gradient {
    background: linear-gradient(122.82deg, #1e2248 8.22%, #4f1997 141.89%);
  }
}

.fc-bg-event {
  @apply !bg-background-z-10 !border-content !border !rounded-md !w-[99%];
}

.fc-day-today {
  @apply !bg-transparent;
}

.fc-timegrid-event {
  @apply !cursor-pointer;
}

.fc-col-header-cell.fc-day-today {
  @apply !text-primary;
}

.fc-col-header-cell-cushion {
  text-transform: capitalize;
}
.fc-event-title {
  line-height: 1;
}

.google-calendar-btn {
  @apply !border-google !bg-white !text-google !align-middle !flex;
}

.google-calendar-btn:hover {
  @apply !border-google !bg-google !text-white !align-middle !flex;
}

.google-calendar-btn-connected {
  @apply !border-google !bg-google !text-white;
}

.google-calendar-btn-connected:hover {
  @apply !border-google !bg-white !text-google;
}
